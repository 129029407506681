<template>
  <popper
    trigger="click"

    :options="{
      placement: 'right',
      modifiers: { offset: { offset: '0,10px' } }
    }">
    <div class="popper" id="popi">

      <input type="checkbox" id="g" name="" value="g" v-model="checkbox">
      <label class="form-check-label" for="g">global (g)</label>

    </br>

      <input type="checkbox" id="i" name="" value="i" v-model="checkbox">
      <label class="form-check-label" for="i">ignore case (i)</label>
    </br>

      <input type="checkbox" id="m" name="" value="m" v-model="checkbox"  >
      <label class="form-check-label" for="m">multiline (m)</label>
    </br>



    </div>
    <v-icon class="flag" slot="reference" id='tip'>mdi-flag</v-icon>



  </popper>
</template>

<script>
  import Popper from 'vue-popperjs';
  import 'vue-popperjs/dist/vue-popper.css';

  export default {
    components: {
      'popper': Popper
    },
    data() {
    return {
      checkbox: []
    };
  },
  watch: {
    checkbox: function() {
      // Emit this information to the parents component
      this.$emit("child-checkbox", this.checkbox);
    }
  }


  }
</script>

<style>
#popi-visible {
    opacity: 1.0;
}
  #popi {
    font-size: .875rem;
    font-family: "Source Code Pro",monospace;
    max-width: 20em;
    background: rgba(30,30,30,0.95);
    color: white;
    padding: .75rem;
    border-radius: 3px;
    text-align:left;
  }

  input[type=checkbox]:checked + label {
    color: #f00;
    font-style: normal;
  }

  .flag:hover{
  color: red;
  cursor: pointer;
  }


</style>
