<template>

<v-card

height="63px"
tile
>
<div class='d-flex'>

<h1 class='font-weight-regular mt-2 ml-2'>Regex Labs</h1>
<div class='mt-3 ml-1'>
<img  :src="require('../assets/atom.png')" height="40"/>
</div>



</div>


</v-card>

</template>

<script>


  export default {
    name: 'navebar',

  }
</script>
