import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBDVTaMWWgu9aY4ehH2yXp-BNIDd9YRUl8",
  authDomain: "regex-labs.firebaseapp.com",
  projectId: "regex-labs-8ec83",
  storageBucket: "regex-labs-8ec83.appspot.com",
  messagingSenderId: "702012562320",
  appId: "1:702012562320:web:ed5d853fe0ee303285b115"
};

  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();
