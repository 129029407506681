<template>
  <v-card
    class="mx-auto"
    max-width="500"
    flat

  >
  <v-spacer></v-spacer>
    <v-toolbar
      color="gray"
      dark
      flat
    >
    <v-spacer></v-spacer>
      <v-toolbar-title>Popular Expretions</v-toolbar-title>

      <v-spacer></v-spacer>

    </v-toolbar>

    <v-list two-line>
      <v-list-item-group

        active-class="blue--text"
      >
        <template v-for="(item, index) in items">
          <v-list-item @click='FireBaseData(item)' :key="item.title" class='texti' >
            <template v-slot:default="{ active }">
              <v-list-item-content flat>
                <v-list-item-title v-text="item.icon"></v-list-item-title>

                <v-list-item-subtitle
                  class="text--primary"
                  v-text="item.text"

                ></v-list-item-subtitle>

                <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
              </v-list-item-content>
              <v-icon
          >
            mdi-test-tube
          </v-icon>
            </template>
          </v-list-item>
          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { db } from '@/fb';
import { auth } from '@/fb';
export default {
  data() {
  return {
    selectedItem: 1,
    items: [],

  };
},
created() {

    // get data from fire base 'exprestions'

    db.collection('expretions').onSnapshot(res => {
    const changes = res.docChanges();

    changes.forEach(change =>{
     if(change.type === 'added'){

        this.items.push({
          ...change.doc.data()
        })
      }
    })
    })
},
methods: {
  FireBaseData: function(item) {
    // Emit this information to the parents component
    this.$emit("child_selecting", item);
    }
      },
}
</script>
<style>
.texti{
  font-size: 12px;

}

</style>
