<template>
  <v-card
    max-width="450"
    class="mx-auto"
    flat
  >
  <h3 class='ml-3 text-center'>Cheetsheet</h3>
    <v-list three-line>
      <template v-for="(item, index) in items">
        <v-subheader
          v-if="item.header"
          :key="item.header"
           v-text="item.header"
        ></v-subheader>

        <v-divider
          v-else-if="item.divider"
          :key="index"
          :inset="item.inset"
        ></v-divider>

        <v-list-item class='texti'
          v-else
          :key="item.title"
        >
          <v-list-item-content>
            <v-list-item-title v-html="item.title"></v-list-item-title>
            <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      items: [{ header: 'Meta Cherectors' },

        {
          title: '\\b',
          subtitle: `<span class="text--primary">Backspace</span>`,
        },
        { divider: true, inset: true },
        {

          title: '\\n<span class="grey--text text--lighten-1"></span>',
          subtitle: `<span class="text--primary">Newline</span> `,
        },
        { divider: true, inset: true },

      ],
    }),
  }
</script>
<style>
.texti{
  font-size: 11px;
}
</style>
