<template>
  <v-app style="
    background: #f5f5f5;">
    <navbar/>
<v-container>

<br>
  <v-row>
    <v-col md='8' sm='12'>
    <v-card class='pl-5 pr-5 pt-5 pb-3'>
      <h3 class='pb-3'>Regular Expression</h3>
    <v-text-field @keyup="regexCheaker()" v-model='regString'
            label="Expression"
            background-color="white"
            outlined
            clearable
          ></v-text-field>
          <div class='pb-1'>
          <v-chip
          class="ma-2"
          color="primary"
        >
          {{ alerts }}: {{ matches_count }} &nbsp;&nbsp;
        </v-chip>
          <pooper @child-checkbox="checkboxValue"></pooper>JavaScript
      </div>
      <textarea placeholder="Please enter your test string here.." v-model='content'
      id="code" name="code">
      </textarea>
    </v-card>
        </v-col>
        <v-col md='4'>
          <v-card class='scroll'>

<expretiontableb @child_selecting='selecting($event)'></expretiontableb>

<v-divider></v-divider>
<cheetsheet/>

</v-card>
        </v-col>
        </v-row>
        <v-row>
          <v-col md='8'>
          <expansion/>
        </v-col>
        </v-row>
</v-container>
  </v-app>
</template>
<script>
import CodeMirror from 'codemirror';
import getSearchCursor from 'codemirror/addon/search/searchcursor';
import pooper from '@/components/pooper';
import { db } from '@/fb';
import navbar from '@/components/navbar';
import expretiontableb from '@/components/expretiontableb';
import cheetsheet from '@/components/cheetsheet';
import expansion from '@/components/expansion';
export default {
  name: 'App',
  components: {
    'pooper': pooper,
    'navbar':navbar,
    'expretiontableb': expretiontableb,
    'cheetsheet':cheetsheet,
    'expansion':expansion,
  },
  data() {
    return {
      regString:"",
      content: "",
      alerts:" Match",
      matches_count:0,
      check: [],
      newCH:[],
      joinCompl:false,
      multiLineFlag:'',
      show:false,
      //selectedItem: 1,
      //items: [],
    }
  },
      mounted() {
      var scope = this;
      this.editor = CodeMirror.fromTextArea(document.getElementById("code"),{
      theme:"3024-day",
      display:"placeholder",
    });
  },
  methods:{
    regexCheaker:function(){
      if (this.newCH === undefined || this.newCH.length == 0) {
          this.joinCompl = false;
        }else{
          this.joinCompl = true;
  }
    this.clearTextInEditor();
      const string = this.content;
      try{
          const regex = eval("/"+this.multiLineFlag+this.regString+"/" + this.newCH.join(''));
          const isExisting = regex.test(this.editor.getValue());
        if(isExisting == true){
          this.insertRegexToCodemirror();
        }else {
        }
      }catch{
        this.clearTextInEditor();
      }
    },
    insertRegexToCodemirror:function(){

      try{
      var val = eval("/"+this.multiLineFlag+this.regString+"/" + this.newCH.join(''));//change
      this.highlightText(val);

              }catch{
                this.clearTextInEditor();
      }
    },
    highlightText(val){

      var cursor = this.editor.getSearchCursor(val);
      while (cursor.findNext()) {
          this.editor.markText(
            cursor.from(),
            cursor.to(),
            { className: 'highlight' }
          );
          this.alerts = "Match";
          this.matches_count +=1;
      }
    },
    checkboxValue: function(params) {
        //this.check = params;
        this.newCH = params;
        if(this.newCH.indexOf("m") == 0){
          this.multiLineFlag = '^';
        }else{
          this.multiLineFlag = '';
        }
        this.regexCheaker();
      },
    clearTextInEditor:function(){
      var ranges=this.editor.getAllMarks();
      for (var i=0;i<ranges.length;i++) ranges[i].clear();
      this.matches_count = 0;
    },
    selecting: function(params){
      console.log(params.content)
    //  var p = params.content.replaceAll("\\\\n", "\n")
      this.matches_count = 0;
      this.regString = params.reg;
      this.editor.setValue(params.content);
      this.insertRegexToCodemirror();
    },
  }
}
</script>
<style> @import '../assets/codemirror.min.css' </style>
<style> @import '../assets/codestyle.css'</style>
